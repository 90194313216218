











































































































































































































































































































import CampaignModel, { CreateCampaignDto } from "@/api/campaign.model";
import clientModel from "@/api/client.model";
import insurerPlanModel from "@/api/insurer_plan_app.model";
import MessageTemplateAppModel from "@/api/message_template_app.model";
import CustomButton from "@/layouts/components/Button.vue";
import Input from "@/layouts/components/Input.vue";
import Checkbox from "@/layouts/components/Checkbox.vue";
import { default as CustomSelect, default as Select } from "@/layouts/components/Select.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Calendar from "./Calendar.vue";
import { BFormFile } from "bootstrap-vue";
import campaignModel from "@/api/campaign.model";
import { showErrorAlert, showSuccessAlert } from "@/helpers";
import CustomInput from "@/layouts/components/Input.vue";
import { ListOptionsDto } from "@/interfaces/list_options.dto";
import ConfirmationModal from "./ConfirmationModal.vue";
import { BOverlay } from "bootstrap-vue";

@Component({
	components: {
		"custom-button": CustomButton,
		"custom-input": CustomInput,
		Select,
		"journey-input": Input,
		Calendar,
		"custom-select": CustomSelect,
		Checkbox,
		BFormFile,
		ConfirmationModal,
		BOverlay,
	},
})
export default class CreateCampaignModal extends Vue {
	@Prop() id: string;
	@Prop({ default: "" }) modalStyle!: string;
	@Prop({ default: "" }) title!: string;
	@Prop({ default: "" }) titlesStyle!: string;
	@Prop({ default: "" }) subtitle!: string;
	@Prop({ default: "" }) icon!: string;
	@Prop({ default: "" }) iconSize!: string;
	@Prop({ default: "mb-2" }) backButtonStyle!: string;
	@Prop({ default: "Voltar" }) backButtonText!: string;
	@Prop({ default: "upload" }) modalType!: "upload" | "default";
	@Prop({ default: false }) isOpen!: boolean;
	@Prop({ default: false }) withBackButton!: boolean;
	@Prop({ default: null }) onBackButton!: Function;
	@Prop({ default: () => [] }) buttons!: any[];
	@Prop({ default: () => null }) onModalClosed!: () => void;

	isModalOpen = this.isOpen;
	model = CampaignModel;
	clientModel = clientModel;

	isConfirmationUploadCampaignButtonDisabled = false;
	isConfirmationDefaultCampaignButtonDisabled = true;

	isConfirmationModalOpen = false;
	subTitle = "";

	file: any = null;

	corporateClientNameToSearch?: string = "";
	messageTemplateOptions: { name: string; value: string }[] = [];

	// HSM SELECT VARS
	messageTemplateAppModel = MessageTemplateAppModel;
	campaignModel = campaignModel;
	isMessageTemplateLoading = false;
	messageTemplateIdFilter = "";
	parsedMessageTemplate = "";
	currentMessageTemplate: any = {};
	matchedValues: string[] = [];
	storedValues: { referenceKey?: string; value?: string }[] = [];
	shouldNotUseSheetClientName = false;
	textContentVariables = "";

	// DEFAULT CAMPAIGN CREATE VARS
	campaignName: string = "";
	clientAboveAge: undefined | number = 0;
	clientBelowAge: undefined | number = 100;
	weeksSinceLastMessageSent?: number = 0;
	isCorporateClientLoading = false;
	corporateClientIdFilter = null;
	isInsurerPlanLoading = false;
	insurerPlanIdFilter = null;
	insurerPlanOptions: { id: string; value: string; name: string }[] = [];
	isTagLoading = false;
	tagIdFilter = null;
	isEnterpriseLoading = false;
	enterpriseIdFilter = null;
	shouldNotUseClientName = false;

	shouldNotUseAI = true;

	// CLIENT SEARCH DATA
	totalCount: number = 0;
	page: number = 1;
	dataPerPage: number = 100;

	shouldDisableInput = true;

	isLoading = false;

	data() {
		return { isModalOpen: this.isOpen };
	}

	closeConfirmationModal() {
		this.isConfirmationModalOpen = false;
	}

	closeModal() {
		this.isModalOpen = false;
		if (this.onModalClosed && typeof this.onModalClosed === "function") {
			this.onModalClosed();
		}
	}

	@Watch("shouldNotUseSheetClientName")
	@Watch("shouldNotUseClientName")
	changeInputDisabledStatus() {
		const currentValueByModalType =
			this.modalType === "upload" ? !this.shouldNotUseSheetClientName : !this.shouldNotUseClientName;
		this.shouldDisableInput = currentValueByModalType;
	}

	@Watch("isOpen")
	async isOpenChanged() {
		this.isModalOpen = this.isOpen;

		if (this.isModalOpen) {
			if (this.modalType === "upload") {
				await this.findMessageTemplatesByCorporateClientName(false);
			}
			this.currentMessageTemplate = {};
			this.parsedMessageTemplate = "";
			this.corporateClientIdFilter = null;
			this.enterpriseIdFilter = null;
			this.campaignName = "";
			this.clientBelowAge = 100;
			this.clientAboveAge = 0;
			this.subTitle = "";
		}
	}
	@Watch("corporateClientIdFilter")
	async loadInsurerPlans() {
		const insurerPlans = await insurerPlanModel.search({
			page: 1,
			limit: 0,
			corporateClientIds: [this.corporateClientIdFilter],
		});

		this.insurerPlanOptions = insurerPlans.data.map((insurerPlan: any) => {
			return { id: insurerPlan.id, name: insurerPlan.name, value: insurerPlan.id };
		});

		await this.findMessageTemplatesByCorporateClientName(true, this.corporateClientIdFilter);
	}

	async findMessageTemplatesByCorporateClientName(shouldSendParams = false, corporateClientId?: any) {
		const params = shouldSendParams ? { corporateClientIds: [corporateClientId] } : {};
		const messageTemplates = await this.messageTemplateAppModel.list(params);
		this.messageTemplateOptions = messageTemplates.length
			? messageTemplates.map((messageTemplate: any) => {
					return { name: messageTemplate.name, value: messageTemplate.id };
			  })
			: [];
	}

	async created() {
		this.clientBelowAge = 100;
		this.clientAboveAge = 0;
		this.weeksSinceLastMessageSent = 0;
		this.insurerPlanOptions = [];
		await this.isOpenChanged();
	}

	async findClients() {
		const params = {
			belowAge: this.clientBelowAge ? this.clientBelowAge : 100,
			aboveAge: this.clientAboveAge ? this.clientAboveAge : 0,
			corporateClientId: this.corporateClientIdFilter ? this.corporateClientIdFilter : undefined!,
			insurerPlanId: this.insurerPlanIdFilter ? this.insurerPlanIdFilter : undefined!,
			enterpriseId: this.enterpriseIdFilter ? this.enterpriseIdFilter : undefined!,
			tagId: this.tagIdFilter ? this.tagIdFilter : undefined!,
			weeksSinceLastMessageSent:
				this.weeksSinceLastMessageSent && this.weeksSinceLastMessageSent > 0
					? this.weeksSinceLastMessageSent
					: undefined!,
			shouldReturnOnlyTotalCount: false,
			shouldGetOnlyActiveClient: true,
			shouldHavePhoneNumber: true,
			page: 1,
			limit: 0,
		};

		const clients = await this.clientModel.search(params);

		this.totalCount = clients.total;
		this.isConfirmationDefaultCampaignButtonDisabled = this.totalCount > 0 ? false : true;
	}

	openConfirmationModal() {
		this.getSubtitle();
		this.isConfirmationModalOpen = true;
	}

	async saveAction() {
		// try {
		// this.isLoading = true;
		let campaign = undefined;
		const formData = new FormData();
		let params: CreateCampaignDto | undefined = undefined;
		if (this.storedValues.length) {
			const formattedStoredValues = this.formatStoredValues(
				this.storedValues as { referenceKey: string; value: string }[],
			);

			this.textContentVariables = JSON.stringify(formattedStoredValues);
		}

		if (this.modalType === "upload") {
			if (!this.corporateClientIdFilter && !this.messageTemplateIdFilter && !this.file) {
				// this.isLoading = false;
				showErrorAlert("Necessário selecionar um cliente corporativo, um template e um arquivo.");
				return;
			}

			formData.append("file", this.file);
			formData.append("messageTemplateId", this.messageTemplateIdFilter);
			formData.append("text", this.parsedMessageTemplate);
			formData.append("corporateClientId", this.corporateClientIdFilter!);
			formData.append("shouldUseSheetClientName", !this.shouldNotUseSheetClientName ? "true" : "false");
			formData.append("shouldUseAI", !this.shouldNotUseAI ? "true" : "false");

			if (this.textContentVariables.length) {
				formData.append("contentVariables", this.textContentVariables);
			}
		} else if (this.modalType === "default") {
			params = {
				name: this.campaignName,
				page: this.page,
				dataPerPage: this.dataPerPage,
				shouldUseClientName: !this.shouldNotUseClientName,
				shouldUseAI: !this.shouldNotUseAI,
				messageTemplate: { id: this.messageTemplateIdFilter },
				enterpriseId: this.enterpriseIdFilter,
				corporateClientId: this.corporateClientIdFilter,
				text: this.parsedMessageTemplate,
				// belowAge: this.clientBelowAge ? 100 : Number(this.clientBelowAge),
				// aboveAge: this.clientAboveAge ? 0 : Number(this.clientAboveAge),
				belowAge: this.clientBelowAge ? Number(this.clientBelowAge) : 100,
				aboveAge: this.clientAboveAge ? Number(this.clientAboveAge) : 0,
				insurerPlanId: this.insurerPlanIdFilter ? this.insurerPlanIdFilter : undefined!,
				tagId: this.tagIdFilter,
				weeksSinceLastMessageSent:
					this.weeksSinceLastMessageSent && this.weeksSinceLastMessageSent > 0
						? this.weeksSinceLastMessageSent
						: undefined!,
				shouldGetOnlyActiveClient: true,
				contentVariables: this.textContentVariables.length ? this.textContentVariables : undefined,
			};
		}
		try {
			showSuccessAlert(
				"Seu mailing está sendo processado, quando for finalizado poderá ser visualizado no painel de listagem de mailings.",
			);
			this.closeModal();
			campaign =
				this.modalType === "upload"
					? await campaignModel.uploadCampaign(formData)
					: await this.model.createCampaign(params!);

			if (campaign?.id) {
				await this.model.updateCampaignParticipantsMessages(campaign.id);
			}

			showSuccessAlert(
				"O envio do seu mailing foi finalizado, caso não esteja visualizando o mailing na listagem atualize a página.",
			);
		} catch (error: any) {
			const errorMessage = error?.message ?? error?.response?.message ?? "Ocorreu um erro. Tente novamente.";
			showErrorAlert(errorMessage);
		} finally {
		}

		// Garantir persistência dos dados
		// location.reload();
		// } catch (error: any) {
		// 	this.isLoading = false;
		// 	const errorMessage = error?.message ?? error?.response?.message ?? "Ocorreu um erro. Tente novamente.";
		// 	showErrorAlert(errorMessage);
		// }
	}

	findFirstOccurrence(value: string, referenceKey: string, index: number) {
		if (value.length) {
			// this.buttonDisabledValidation[index] = true;
			this.storedValues[index] = {};
			Object.assign(this.storedValues[index], { referenceKey, value });
		} else {
			Object.assign(this.storedValues[index], { referenceKey, value: referenceKey });
		}

		this.replaceHSMVariableByReferenceKey();
	}

	async changeTemplate() {
		this.matchedValues = [];
		this.parsedMessageTemplate = "";
		if (this.messageTemplateIdFilter) {
			this.storedValues = [];
			this.currentMessageTemplate = await this.loadMessageTemplates(this.messageTemplateIdFilter);
			this.parsedMessageTemplate = this.currentMessageTemplate.text;
			this.loadHSMVariables(this.currentMessageTemplate.text);
		}
	}

	async loadMessageTemplates(id: string) {
		return this.messageTemplateAppModel.getOne(id);
	}

	loadHSMVariables(text: string) {
		const specialCharacterRegex: RegExp = /\{\{(.*?)\}\}/gu;
		this.matchedValues = [...text.matchAll(specialCharacterRegex)].map((matchedValue: any) => matchedValue);
		// this.matchedValues.forEach((value, index) => {
		// 	this.buttonDisabledValidation[index] = false;
		// });
	}

	replaceHSMVariableByReferenceKey() {
		let text = this.currentMessageTemplate.text;
		this.storedValues.forEach(storedValue => {
			const { referenceKey, value } = storedValue;
			if (referenceKey && value) {
				text = text.replace(referenceKey, value);
			}
		});

		this.parsedMessageTemplate = text;
		// this.buttonDisabled = this.buttonDisabledValidation.every(validation => validation) ? false : true;
	}

	getSubtitle() {
		const corporateClientName = this.currentMessageTemplate["corporateClient.name"] ?? "";
		const text = this.parsedMessageTemplate ?? "";
		const clientAboveAge = this.clientAboveAge ?? 0;
		const clientBelowAge = this.clientBelowAge ?? 100;
		const dataCount = this.totalCount < this.dataPerPage ? this.totalCount : this.dataPerPage;
		const campaignName = this.campaignName ?? "";
		const shouldUseAI = this.shouldNotUseAI ? "Não" : "Sim";
		this.subTitle =
			this.modalType === "default"
				? `Deseja realizar o envio do Mailing com as seguintes informações?
		 <p class="m-0">Cliente corporativo: <b class='text-journey-orange'>${corporateClientName}</b></p>
		 <p class="m-0">Texto: <b>${text}</b></p>
		 <p class="m-0">Nome do mailing: <b>${campaignName}</b></p>
		 <p class="m-0">Idade(Acima): <b>${clientAboveAge}</b></p>
		 <p class="m-0">Idade(Abaixo): <b>${clientBelowAge}</b></p>
		 <p class="m-0">Contatos: <b>${dataCount}</b></p>
      <p class="">IA Ativa: <b>${shouldUseAI}</b></p>
		 `
				: `Deseja realizar o envio do Mailing com as seguintes informações?
		 <p class="m-0">Cliente corporativo: <b>${corporateClientName}</b></p>
		 <p class="m-0">Texto: <b>${text}</b></p>
      <p class="">IA Ativa: <b>${shouldUseAI}</b></p>`;
	}

	formatStoredValues(variables: { referenceKey: string; value: string }[]) {
		const object = {};

		variables.forEach(variable => {
			// eslint-disable-next-line require-unicode-regexp
			const match = variable.referenceKey.match(/\{\{(\d+)\}\}/);
			const key = match ? match[1] : null;

			Object.assign(object, { [<string>key]: variable.value });
		});

		return object;
	}
}
