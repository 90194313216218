










































import CampaignModel from "@/api/campaign.model";
import corporateClientModel from "@/api/corporate_client.model";
import CampaignModal from "@/layouts/components/CampaignModal.vue";
import CreateCampaignModal from "@/layouts/components/CreateCampaignModal.vue";
import { Component, Vue, Watch } from "vue-property-decorator";

import EntityCrud from "../entity/EntityCrud.vue";
import { formatHoursDayMonthYear } from "@/helpers/utils";

@Component({
	components: { EntityCrud, CampaignModal, CreateCampaignModal },
})
export default class ChatChannelReport extends Vue {
	model = CampaignModel;
	title = "";
	isReadOnly: boolean = true;
	defaultEntity = {};
	kind = "campaignTable";
	withEntityHeader = true;
	cleanFormKeys = {};
	isLoading = false;
	shouldShowHeaderName = false;

	shouldGoToPreviousPageAfterRemove = false;

	campaignModalIsOpen: boolean = false;
	createCampaignModalIsOpen: boolean = false;
	eventType: string = "";
	campaign: any = {};
	modalType: "upload" | "default" = "upload";

	customButtons = [
		{
			icon: "add-icon.svg",
			variant: "tertiary",
			buttonAction: () => {
				this.openCreateCampaignModal("default");
			},
		},
		{
			icon: "secondary-upload-file-icon.svg",
			variant: "tertiary",
			iconStyle: "filter-black w-6 h-6 cursor-pointer",
			buttonAction: () => {
				this.openCreateCampaignModal("upload");
			},
		},
	];

	tableColumns = [
		{
			name: "Título",
			key: "name",
		},
		{
			name: "Data de criação",
			key: "createdDate",
			kind: "date",
			onTransform: formatHoursDayMonthYear,
		},
		{
			name: "Cliente corporativo",
			key: "corporateClient.name",
			hasSelect: true,
			options: [{}],
		},
		{
			name: "Ações",
			kind: "actions",
			actions: [
				{ icon: "file-earmark-bar-graph", eventName: "openCampaignParticipantList", type: "VueIcon", style: "h3" },
			],
		},
	];
	initialSort: { key: "createdDate"; direction: "DESC" };

	get currentId() {
		return this.$route.params.id;
	}

	@Watch("currentId")
	changeReadOnly() {
		if (!this.currentId) {
			this.isReadOnly = true;
		}
		if (this.currentId === "novo") {
			this.defaultEntity = {};
		}
	}

	titleDictionary(
		title: keyof {
			openCampaignParticipantList: string;
		},
	) {
		const dictionary = {
			openCampaignParticipantList: "Abrir lista de participantes",
		};

		return dictionary[title];
	}

	actionsEvent(event: "openCampaignParticipantList", row: any) {
		this.title = this.titleDictionary(event);
		this.campaign = row;

		this.eventType = event;
		this.campaignModalIsOpen = true;
	}

	openCreateCampaignModal(type: "upload" | "default") {
		this.modalType = type;
		this.createCampaignModalIsOpen = true;
	}

	closeModal() {
		this.campaignModalIsOpen = false;
		this.createCampaignModalIsOpen = false;
	}

	onBack() {
		this.$router.back();
	}

	async mounted() {
		const foundColumn = this.tableColumns.find(tableColumn => tableColumn.key === "corporateClient.name");
		if (foundColumn) {
			const corporateClients = await corporateClientModel.get("");
			foundColumn.options = corporateClients.map((corporateClient: any) => {
				return { name: corporateClient.name, value: corporateClient.id };
			});
		}
	}
}
