import { CrudModel } from "./_crud";
import { crudGet, crudPost, crudRead } from "./_request";

export class CreateCampaignDto {
	name: string;
	page: number;
	dataPerPage: number;
	shouldUseClientName: boolean;
	messageTemplate: { id: string };
	text: string;
	aboveAge: number;
	belowAge: number;
	corporateClientId: string | null;
	insurerPlanId: string | null;
	enterpriseId: string | null;
	tagId: string | null;
	weeksSinceLastMessageSent: number;
	shouldGetOnlyActiveClient: boolean;
	shouldUseAI: boolean;
	contentVariables?: string;
}

export class campaignModel extends CrudModel {
	constructor() {
		super("admin/campaign", [], []);
	}
	async getCampaigns(params?: any) {
		return this.getParam(params);
	}

	async getCampaignsParticipants({
		page,
		limit,
		campaignId,
		searchParam,
	}: {
		page: number;
		limit: number;
		campaignId: string;
		searchParam?: string;
	}) {
		return crudGet(this.endpoint, "participants", { page, limit, campaignId, searchParam });
	}

	async uploadCampaign(formData: FormData) {
		return crudPost(this.endpoint, "uploadCampaign", formData);
	}

	async createCampaign(createCampaignDto: CreateCampaignDto) {
		return crudPost(this.endpoint, "create", createCampaignDto);
	}

	async updateCampaignParticipantsMessages(campaignId: string) {
		return crudPost(this.endpoint, "updateCampaignParticipantsMessages", { id: campaignId });
	}
}

export default new campaignModel();
