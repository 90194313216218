var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full"},[_c('campaign-modal',{key:_vm.campaign.id,attrs:{"isOpen":_vm.campaignModalIsOpen,"onModalClosed":_vm.closeModal,"withBackButton":true,"eventType":_vm.eventType,"campaign":_vm.campaign,"emitEventName":'closeChannel',"title":_vm.title},on:{"closeChannel":_vm.closeModal}}),_c('create-campaign-modal',{attrs:{"modalType":_vm.modalType,"isOpen":_vm.createCampaignModalIsOpen,"onModalClosed":_vm.closeModal,"withBackButton":true}}),_c('entity-crud',_vm._b({attrs:{"shouldShowHeaderName":_vm.shouldShowHeaderName,"shouldShowButtons":true,"shouldShowCustomButtons":true,"customButtons":_vm.customButtons,"shouldShowDateSearch":false,"onRowClick":null,"shouldDisableRowClick":true},on:{"actionsEvent":_vm.actionsEvent}},'entity-crud',{
			model: _vm.model,
			title: _vm.title,
			tableColumns: _vm.tableColumns,
			shouldGoToPreviousPageAfterRemove: _vm.shouldGoToPreviousPageAfterRemove,
			kind: _vm.kind,
			initialSort: { key: 'createdDate', direction: 'DESC' },
		},false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }