









import { BCard, BCardText, BCardTitle } from "bootstrap-vue";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({ components: { BCard, BCardText, BCardTitle } })
export default class SimpleMetricsCard extends Vue {
	@Prop({ default: "" }) title: string;
	@Prop({ default: 0 }) value: string;
}
