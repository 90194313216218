



































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CustomButton from "@/layouts/components/Button.vue";
import Select from "@/layouts/components/Select.vue";
import Input from "@/layouts/components/Input.vue";
import { formatDateToISO, toIsoDate } from "@/helpers/calendar";
import Calendar from "./Calendar.vue";
import { showErrorAlert } from "@/helpers";
import SolutionAppointmentModel from "@/api/solution_appointment.model";
import CampaignModel from "@/api/campaign.model";
import { CreateQueryParams } from "@nestjsx/crud-request";
import { IUser, UserRole } from "@/interfaces/user";
import CustomSelect from "@/layouts/components/Select.vue";
import debounce from "debounce";
import Table from "@/layouts/components/Table.vue";
import { ChatMessageStatus } from "@/interfaces/chat";
import SimpleMetricsCard from "@/views/dashboard/SimpleMetricsCard.vue";

@Component({
	components: {
		"custom-button": CustomButton,
		Select,
		"journey-input": Input,
		Calendar,
		"custom-select": CustomSelect,
		Table,
		SimpleMetricsCard,
	},
})
export default class CampaignModal extends Vue {
	@Prop() id: string;
	@Prop({ default: "" }) modalStyle!: string;
	@Prop({ default: "" }) title!: string;
	@Prop({ default: "" }) titlesStyle!: string;
	@Prop({ default: "" }) subtitle!: string;
	@Prop({ default: "" }) icon!: string;
	@Prop({ default: "" }) iconSize!: string;
	@Prop({ default: "mb-2" }) backButtonStyle!: string;
	@Prop({ default: "Voltar" }) backButtonText!: string;
	@Prop({ default: false }) isOpen!: boolean;
	@Prop({ default: false }) withBackButton!: boolean;
	@Prop({ default: null }) onBackButton!: Function;
	@Prop({ default: () => [] }) buttons!: any[];
	@Prop({ default: () => null }) onModalClosed!: () => void;
	@Prop({}) emitEventName: string;
	@Prop({}) eventType!: string;
	@Prop({}) campaign!: any;

	isModalOpen = this.isOpen;

	toIsoDate = toIsoDate;

	selectedDate: string = "";
	formattedSelectedDate: string = "";
	model = CampaignModel;
	// userModel = UserModel;

	campaignId: string = "";

	campaignParticipants: any = [];
	report = {};
	tableColumns = [
		{ key: "name", name: "Nome" },
		{ key: "phoneNumber", name: "Telefone" },
		{ key: "status", name: "Status" },
		{ key: "wasAnswered", name: "Respondido" },
	];
	tableData: { name: string; phoneNumber: string; status: ChatMessageStatus }[] = [];

	// debouncedUserSearch = debounce(this.onSearch, 300);

	data() {
		return { isModalOpen: this.isOpen };
	}

	closeModal() {
		this.isModalOpen = false;
		if (this.onModalClosed && typeof this.onModalClosed === "function") {
			this.onModalClosed();
		}
	}
	@Watch("isOpen")
	async isOpenChanged() {
		this.isModalOpen = this.isOpen;

		if (this.isModalOpen) {
			if (this.eventType === "openCampaignParticipantList") {
				const response = await this.loadParticipants();
				this.campaignParticipants = response.data;
				this.report = response.report;
				this.tableData = this.campaignParticipants.map((data: any) => {
					return {
						name: data.name ?? "-",
						phoneNumber: data.chatChannel.phoneNumber,
						status: this.chatMessageStatusDictionary(data.chatMessage.status),
						wasAnswered: data.wasAnswered ? "Sim" : "Não",
					};
				});
			}
		}
	}

	chatMessageStatusDictionary(status: ChatMessageStatus) {
		const dictionary = {
			[ChatMessageStatus.UNDELIVERED]: "Não entregue",
			[ChatMessageStatus.DELIVERED]: "Entregue",
			[ChatMessageStatus.ERROR]: "Erro",
			[ChatMessageStatus.FAILED]: "Falha",
			[ChatMessageStatus.QUEUED]: "Em fila",
			[ChatMessageStatus.READ]: "Lida",
			[ChatMessageStatus.SENT]: "Enviada",
		};

		return dictionary[status];
	}

	reportNameDictionary(name: "contacts" | "delivered" | "read" | "answered" | "failed" | "undelivered") {
		const dictionary = {
			["contacts"]: "Contatos",
			["delivered"]: "Entregue",
			["read"]: "Lida",
			["answered"]: "Respondido",
			["failed"]: "Falha",
			["undelivered"]: "Não entregue",
		};

		return dictionary[name];
	}

	async loadParticipants() {
		return this.model.getCampaignsParticipants({ page: 1, limit: 0, campaignId: this.campaign.id });
	}

	async created() {
		await this.isOpenChanged();
	}

	async inputChange(value: string) {
		this.campaignId = value;
	}

	async saveAction() {}

	getQueryParameters(name?: string, lastName?: string): CreateQueryParams {
		const $or = name ? [{ name: { $cont: name } }, { lastName: { $cont: lastName } }] : [];
		return {
			join: [],
			limit: 50,
			page: 1,
			search: {
				$or,
				role: {
					$in: [UserRole.HEALTH_PROMOTER],
				},
			},
			sort: [{ field: "name", order: "ASC" }],
		};
	}
}
